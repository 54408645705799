import React from 'react';
import { Button, SvgIcon, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PaperSheet from '../common/PaperSheet';
import { getRedirectUri } from '../redirect-to';

function ApplicationAccessGranted() {
  const redirectUri = getRedirectUri();
  return (
    <PaperSheet>
      <SvgIcon style={{ fontSize: 180 }} nativeColor={green[500]}>
        <CheckCircleIcon />
      </SvgIcon>

      <Typography variant="body1">Go to the application you want to use.</Typography>

      {redirectUri ? (
        <Button variant="contained" color="primary" href={redirectUri} target="_self" rel="external nofollow">
          Open application
        </Button>
      ) : null}
    </PaperSheet>
  );
}

ApplicationAccessGranted.propTypes = {};

export default ApplicationAccessGranted;
