export function parseQueryString(query) {
  const parameters = {};
  const questionMarkPosition = query.indexOf('?');
  const pairs = query.substr(questionMarkPosition + 1).split('&');
  if (questionMarkPosition !== -1 || query.indexOf('=') !== -1) {
    pairs.map(pair => pair.split('=')).forEach(([key, value]) => {
      parameters[decodeURIComponent(key)] = value ? decodeURIComponent(value) : undefined;
    });
  }
  return parameters;
}
