import React from 'react';
import PaperSheet from '../common/PaperSheet';
import { Typography } from '@material-ui/core';

function VisitApplicationToAuthenticate() {
  return (
    <PaperSheet>
      <Typography variant="title" gutterBottom>
        Unauthenticated
      </Typography>

      <Typography variant="body1" gutterBottom>
        Open a URL of an application that you want to use.
      </Typography>
    </PaperSheet>
  );
}

VisitApplicationToAuthenticate.propTypes = {};

export default VisitApplicationToAuthenticate;
