import React from 'react';
import sessionInfoContainer from './SessionInfoContainer';
import { Subscribe } from 'unstated';
import { Button, Typography, Avatar, Hidden } from '@material-ui/core';
import './UserInfo.css';
import { map, head } from 'lodash';
import { authenticateUriWithRedirectToFromQuery } from '../redirect-to';

const UserInfo = () => {
  return (
    <Subscribe to={[sessionInfoContainer]}>
      {container => {
        function logout() {
          container.logout().then(() => {
            window.location.href = authenticateUriWithRedirectToFromQuery();
          });
        }

        const { sessionInfo } = container.state;
        if (sessionInfo) {
          const { user } = sessionInfo;
          if (user) {
            return (
              <div className="SessionInfo">
                <Hidden xsDown implementation="css">
                  <Typography variant="body1" color="inherit" gutterBottom={false} alt={user.name}>
                    {user.emailAddress}
                  </Typography>
                </Hidden>

                <Avatar style={{ backgroundColor: '#ffc107', marginLeft: '24px', marginRight: '8px' }}>
                  {initials(user.name)}
                </Avatar>

                <Button variant="text" color="inherit" onClick={logout}>
                  Logout
                </Button>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      }}
    </Subscribe>
  );
};

function initials(name) {
  const parts = name.split(/ /, 2);
  return map(parts, head).join('');
}

export default UserInfo;
