import React from 'react';
import PropTypes from 'prop-types';
import PaperSheet from './PaperSheet';
import { Typography } from '@material-ui/core';

function SimpleErrorPage({ title, children }) {
  return (
    <PaperSheet>
      <Typography variant="title" gutterBottom>
        {title}
      </Typography>
      {children}
    </PaperSheet>
  );
}

SimpleErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default SimpleErrorPage;
