import React from 'react';
import { CircularProgress, Fade } from '@material-ui/core';

type Props = {
  loading: boolean,
  children: Node
};

function PageLoading({ loading, children }: Props) {
  return (
    <Fade in={loading} timeout={{ enter: 1000, exit: 1000 }}>
      <div style={{ display: 'flex', width: '100%', marginTop: '25vh', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress style={{ marginBottom: '16px' }} />
        <div>{children}</div>
      </div>
    </Fade>
  );
}

PageLoading.defaultProps = {
  loading: true
};

export default PageLoading;
