import axios from 'axios';

export function getSessionInfo(applicationId) {
  const parameters = applicationId ? `applicationId=${encodeURIComponent(applicationId)}` : '';
  return axios.get(`api/session?${parameters}`).then(response => response.data);
}

export function logoutSession() {
  return axios.delete('api/session');
}
