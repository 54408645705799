import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import PageLoading from './common/PageLoading';
import { Provider } from 'unstated';
import { throttle } from 'lodash';
import sessionInfoContainer from './session/SessionInfoContainer';
import Authentication from './authentication/Authentication';
import Header from './common/Header';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import ApplicationAccessDenied from './authentication/ApplicationAccessDenied';
import { performRedirectTo } from './redirect-to';
import { parseQueryString } from './uri';

const AdminRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "admin-routes" */ './admin/AdminRoutes'),
  loading: ({ pastDelay }) => <PageLoading loading={pastDelay} />,
  delay: 500
});

function fetchSession() {
  const applicationId = parseQueryString(global.window.location.search).applicationId;
  sessionInfoContainer.fetch(applicationId).then(session => {
    if (session.state === 'ApplicationAccessGranted') {
      performRedirectTo();
    }
  });
}

fetchSession();

global.window.onfocus = throttle(fetchSession, 5000, { trailing: false });

class App extends Component {
  render() {
    return (
      <Provider inject={[sessionInfoContainer]}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Header />

            <BrowserRouter>
              <Switch>
                <Route path="/" exact component={Authentication} />
                <Route path="/application-access-denied" exact component={ApplicationAccessDenied} />
                <Route path="/admin" component={AdminRoutes} />
                <Redirect to="/" />
              </Switch>
            </BrowserRouter>
          </div>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
