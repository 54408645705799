import React from 'react';
import { get } from 'lodash';
import sessionInfoContainer from '../session/SessionInfoContainer';
import { Subscribe } from 'unstated';
import { Typography } from '@material-ui/core';
import PageLoading from '../common/PageLoading';
import AuthenticationDenied from './AuthenticationDenied';
import VerifyCode from './one-time-password/VerifyCode';
import Loadable from 'react-loadable';
import ApplicationAccessDenied from './ApplicationAccessDenied';
import ApplicationAccessGranted from './ApplicationAccessGranted';
import GoogleAuthenticationNeeded from './GoogleAuthenticationNeeded';
import SimpleErrorPage from '../common/SimpleErrorPage';
import Authenticated from './Authenticated';
import VisitApplicationToAuthenticate from './VisitApplicationToAuthenticate';
import { getRedirectUri } from '../redirect-to';

const GenerateKey = Loadable({
  loader: () => import(/* webpackChunkName: "one-time-password-generate-key" */ './one-time-password/GenerateKey'),
  loading: ({ pastDelay }) => <PageLoading loading={pastDelay} />,
  delay: 500
});

const Authentication = () => {
  return (
    <Subscribe to={[sessionInfoContainer]}>
      {container => {
        const { sessionInfo, error } = container.state;
        if (sessionInfo) {
          switch (sessionInfo.state) {
            case 'Authenticated':
              return <Authenticated />;

            case 'AuthenticationDenied':
              return <AuthenticationDenied />;

            case 'ApplicationAccessDenied':
              return <ApplicationAccessDenied />;

            case 'ApplicationAccessGranted':
              return <ApplicationAccessGranted />;

            case 'GoogleAuthenticationNeeded':
              if (getRedirectUri() !== undefined) {
                return <GoogleAuthenticationNeeded />;
              } else {
                return <VisitApplicationToAuthenticate />;
              }

            case 'OneTimePasswordAuthenticationNeeded':
              if (sessionInfo.haveOneTimePasswordKey) {
                return <VerifyCode oneTimePasswordIssuerName={sessionInfo.oneTimePasswordIssuerName} />;
              } else {
                return <GenerateKey />;
              }

            default:
              return (
                <SimpleErrorPage title="Unknown state">
                  <Typography variant="body1" gutterBottom>
                    Unknown session state, "{sessionInfo.state}".
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Try deleting cookies and retrying.
                  </Typography>
                </SimpleErrorPage>
              );
          }
        } else if (error) {
          return (
            <SimpleErrorPage title="Failed to load">
              <Typography variant="body1" gutterBottom>
                Error when loading page.
              </Typography>
              <pre>{JSON.stringify(get(error, 'response', {}), null, 2)}</pre>
            </SimpleErrorPage>
          );
        } else {
          return <PageLoading />;
        }
      }}
    </Subscribe>
  );
};

Authentication.propTypes = {};

export default Authentication;
