import React from 'react';
import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import UserInfo from '../session/UserInfo';

const styles = {
  title: {
    flex: 1,
    textAlign: 'left'
  }
};

const Header = ({ classes }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="title" color="inherit" className={classes.title}>
          Gatekeeper
        </Typography>

        <UserInfo />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
