import React from 'react';
import PaperSheet from '../common/PaperSheet';
import { Typography } from '@material-ui/core';

function Authenticated() {
  return (
    <PaperSheet>
      <Typography variant="title" gutterBottom>
        Authenticated
      </Typography>
      <Typography variant="body1" gutterBottom>
        You are logged in. Go to the application you want access in your browser.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Some applications might require you to also enter a one time password.
      </Typography>
    </PaperSheet>
  );
}

Authenticated.propTypes = {};

export default Authenticated;
