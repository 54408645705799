import axios from 'axios';

export function generateKey() {
  return axios.post('api/authentication/one-time-password/key/generate').then(response => response.data);
}

export function verifyGeneratedKey(keyToken, code) {
  return axios.post('api/authentication/one-time-password/key/verify', { keyToken, code });
}

export function verifyCode(code) {
  return axios.post('api/authentication/one-time-password/verify', { code });
}
