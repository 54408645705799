import React from 'react';
import { Paper, withStyles, Slide } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: theme.mixins.gutters({
    maxWidth: '500px',
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3
  })
});

function PaperSheet(props) {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <Slide appear={true} in={true} direction="down" mountOnEnter={true}>
        <Paper className={classes.paper} elevation={4}>
          {children}
        </Paper>
      </Slide>
    </div>
  );
}

export default withStyles(styles)(PaperSheet);
