import React from 'react';
import PaperSheet from '../../common/PaperSheet';
import { green } from '@material-ui/core/colors';
import { CircularProgress, Typography, Grid } from '@material-ui/core';

function SuccessfulLogin() {
  return (
    <PaperSheet>
      <Grid container spacing={16} direction="column" justify="flex-start" alignItems="center">
        <Grid item>
          <Typography variant="title">Login successful</Typography>
        </Grid>

        <Grid item>
          <CircularProgress size={50} style={{ color: green[500] }} />
        </Grid>

        <Grid item>
          <Typography variant="body1">Loading your application...</Typography>
        </Grid>
      </Grid>
    </PaperSheet>
  );
}

SuccessfulLogin.propTypes = {};

export default SuccessfulLogin;
