import { Container } from 'unstated';
import { getSessionInfo, logoutSession } from './api';

type UserInfo = {
  name: string,
  emailAddress: string
};

type SessionInfoState = {
  sessionInfo: ?UserInfo,
  loading: boolean,
  error: ?object
};

export class SessionInfoContainer extends Container<SessionInfoState> {
  state = {
    loading: false
  };

  fetch(applicationId) {
    this.setState(() => ({ loading: true }));
    return getSessionInfo(applicationId)
      .then(sessionInfo => {
        this.setState({ sessionInfo });
        return sessionInfo;
      })
      .catch(error => {
        this.setState({ error });
        return Promise.reject(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  logout() {
    return logoutSession().then(() => {
      this.setState({ sessionInfo: null });
    });
  }
}

const sessionInfoContainer = new SessionInfoContainer();

export default sessionInfoContainer;
