import { parseQueryString } from './uri';

export function performRedirectTo() {
  const toUrl = getRedirectUri();
  if (toUrl) {
    global.window.location.href = toUrl;
  }
}

export function getRedirectUri() {
  const query = parseQueryString(global.window.location.search);
  return query.to;
}

export function authenticateUriWithRedirectToFromQuery() {
  const { to, applicationId } = parseQueryString(global.window.location.search);
  if (to && applicationId) {
    return authenticateUriWithRedirectTo(applicationId, to);
  } else {
    return '/';
  }
}

export function authenticateUriWithRedirectTo(applicationId, to) {
  return `/google/authenticate?to=${encodeURIComponent(to)}&applicationId=${encodeURIComponent(applicationId)}`;
}
