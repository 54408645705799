import React from 'react';
import PaperSheet from '../common/PaperSheet';
import { Button, Typography } from '@material-ui/core';
import { authenticateUriWithRedirectToFromQuery } from '../redirect-to';

function GoogleAuthenticationNeeded() {
  return (
    <PaperSheet>
      <Typography variant="title" gutterBottom>
        Unauthenticated
      </Typography>

      <Typography variant="body1" gutterBottom>
        You are not logged in. Please login with your Google account.
      </Typography>

      <Button variant="contained" color="primary" href={authenticateUriWithRedirectToFromQuery()}>
        Login
      </Button>
    </PaperSheet>
  );
}

GoogleAuthenticationNeeded.propTypes = {};

export default GoogleAuthenticationNeeded;
