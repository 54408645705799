import React from 'react';
import { throttle } from 'lodash';
import { verifyCode } from './api';
import { Button, TextField, Typography, Grid } from '@material-ui/core';
import PaperSheet from '../../common/PaperSheet';
import SuccessfulLogin from './SuccessfulLogin';
import PropTypes from 'prop-types';
import { performRedirectTo } from '../../redirect-to';

class VerifyCode extends React.Component {
  static propTypes = {
    oneTimePasswordIssuerName: PropTypes.string.isRequired
  };

  static defaultProps = {
    oneTimePasswordIssuerName: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      verificationCode: '',
      successful: false
    };
  }

  handleVerificationCodeChange = event => {
    this.setState({
      verificationCode: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const verificationCode = parseInt(this.state.verificationCode, 10);
    this.checkVerificationCode(verificationCode);
  };

  checkVerificationCode = throttle(verificationCode => {
    this.setState({ checkingVerificationCode: true, incorrectVerificationCode: false });
    verifyCode(verificationCode)
      .then(
        () => {
          this.setState({ successful: true });
          performRedirectTo();
        },
        () => {
          this.setState({ verificationCode: '', incorrectVerificationCode: true });
        }
      )
      .finally(() => {
        this.setState({ checkingVerificationCode: false });
      });
  }, 1000);

  render() {
    const verificationCodeError = this.state.incorrectVerificationCode && !this.state.verificationCode;
    if (this.state.successful) return <SuccessfulLogin />;

    const { oneTimePasswordIssuerName } = this.props;

    return (
      <PaperSheet>
        <form onSubmit={this.handleSubmit}>
          <Typography variant="title" gutterBottom>
            2 factor-authentication
          </Typography>
          <Typography variant="body1" gutterBottom>
            Enter the 6 digit code shown under <b style={{ display: 'inline-block' }}>{oneTimePasswordIssuerName}</b> in
            the Google Authenticator app on your phone.
          </Typography>
          <Grid container spacing={16} alignItems="baseline">
            <Grid item xs={8}>
              <TextField
                label="Code"
                value={this.state.verificationCode}
                onChange={this.handleVerificationCodeChange}
                autoFocus
                fullWidth
                inputProps={{ minLength: 6, maxLength: 6 }}
                type="tel"
                error={verificationCodeError}
                helperText={verificationCodeError ? 'The verification code was incorrect' : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Verify
              </Button>
            </Grid>
          </Grid>
        </form>
      </PaperSheet>
    );
  }
}

export default VerifyCode;
