import React from 'react';
import PaperSheet from '../common/PaperSheet';

function ApplicationAccessDenied() {
  return <PaperSheet>You are not allowed to access application.</PaperSheet>;
}

ApplicationAccessDenied.propTypes = {};

export default ApplicationAccessDenied;
