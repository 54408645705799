import React from 'react';
import { logoutSession } from '../session/api';
import { Button, Typography } from '@material-ui/core';
import PaperSheet from '../common/PaperSheet';
import { authenticateUriWithRedirectToFromQuery } from '../redirect-to';

function logoutAndRelogin() {
  logoutSession().finally(() => {
    window.location.href = authenticateUriWithRedirectToFromQuery();
  });
}

const AuthenticationDenied = () => {
  return (
    <PaperSheet>
      <Typography variant="title" gutterBottom>
        Access denied
      </Typography>
      <Typography variant="body1" gutterBottom>
        The account you logged in with do not have access to this service. Try again with another account.
      </Typography>

      <Button variant="contained" color="primary" onClick={logoutAndRelogin}>
        Login with another user
      </Button>
    </PaperSheet>
  );
};

AuthenticationDenied.propTypes = {};

export default AuthenticationDenied;
